.o-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1em;
}

.o-icon__symbol {
  display: block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}
