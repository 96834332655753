$bp-large-item-width: ceil($content-width / 3);

.c-slider {
  margin: 5.55em 0;
  width: 100%;

  @include bp-medium {
    margin: 11.1em 0 2.78em;
  }
}

.c-slider__frame {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.c-slider__list {
  position: relative;
  width: 100%;
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  transition: transform $global-partial-transition;
  flex-wrap: wrap;
  justify-content: center;

  .is-transition-disabled & {
    transition-duration: 0ms;
  }

  .is-enabled & {
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
}

.c-slider__item {
  position: relative;
  flex: 0 0 auto;
  margin: 0;
  padding: 10px;
  width: 100%;
  pointer-events: none;

  &.is-active {
    pointer-events: auto;
  }

  @include bp-small {
    width: 50%;
  }

  @include bp-medium {
    width: 33.33%;
  }

  @include bp-large {
    padding: 20px;
  }

  &.js-clone-prev {
    order: -1;
  }

  &.js-clone-next {
    order: 1;
  }
}

.c-slider__content {
  width: 100%;
  pointer-events: none;

  .is-past &,
  .is-future & {
    background: rgba($color-bg, 0.5);
  }

  .is-transition-disabled & {
    transition-duration: 0ms;
  }

  @include bp-full {
    .is-enabled & {
      backface-visibility: hidden;
      margin-left: -100vw;
      opacity: 0;
    }

    .is-past & {
      margin-left: -50vw;
      transform: translateX(($content-width+$bp-large-item-width) / 2);
      opacity: 1;
    }

    .is-active & {
      margin-left: 0vw; /* stylelint-disable-line length-zero-no-unit */ /* Fix IE11 flickering bug */
      transform: translateX(0);
      opacity: 1;
    }

    .is-future & {
      margin-left: 50vw;
      transform: translateX(-($content-width+$bp-large-item-width) / 2);
      opacity: 1;
    }

    .is-future + .c-slider__item & {
      margin-left: 100vw;
    }
  }
}

.c-slider__frame--uncropped {
  overflow: visible;
}
