.c-contact-form {
  @include bp-medium {
    margin: 0 0 0 auto;
    max-width: 370px;
    color: $color-bg;
  }
}

.c-contact-form__input {
  background: transparent;
  color: $color-text;
  border-bottom-color: $color-text;

  @include bp-medium {
    color: $color-bg;
    border-bottom-color: $color-bg;
  }
}
