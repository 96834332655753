$feature-more-spacing: 40px;
$feature-more-button: 48px;
$feature-more-height: $feature-more-spacing + $feature-more-button;

.c-feature {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding-left: 24px;
  padding-right: 24px;
  text-align: center;
  transition: $global-transition;

  @include bp-large {
    padding-left: 48px;
    padding-right: 48px;
  }
}

.c-feature--card {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: left;
  background: $color-bg;
  border-radius: 0 0 $content-radius $content-radius;
  box-shadow: $content-shadow;
}

.c-feature__description {
  height: 100%;
}

.c-feature__image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 168px;
  max-height: 9.33em;
  width: 100%;
  margin-bottom: 40px;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  img[src$='@2x.png'] {
    transform: scale(0.5);
    max-width: 200%;
    max-height: 200%;
  }
}

.c-feature-list {
  list-style: none;
}

.c-feature-list__item {
  text-align: center;
}

.c-feature-list__icon {
  margin-right: 15px;
  font-size: 0.8rem;
  color: $color-blue;
}
