.o-section {
  position: relative;
  margin: 5.55em 0 0; /* approx. 150px on xlarge */

  @include bp-medium {
    margin-top: 8.33em; /* approx. 150px on xlarge */
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
    pointer-events: none;
  }
}

.o-section--large-spacing {
  @include bp-medium {
    margin-top: 16.65em; /* aprox. 300px on xlarge */
  }
}

.o-section--xlarge-spacing {
  @include bp-medium {
    margin-top: 22.2em; /* aprox. 400px on xlarge */
  }
}

.o-section--mobile-translucent::before {
  opacity: 0.5;

  @include bp-medium {
    opacity: 1;
  }
}

.o-section--five::before {
  width: (1678px/$content-width)*100%;
  max-width: 1678px;
  padding-top: (1607px/$content-width)*100%;
  background-image: url('../assets/images/section-five.png');
  background-position: left top;
  transform: translateX(-15.5%) translateY(-58%);

  @include bp-medium {
    transform: translateX(-15.5%) translateY(24%);
    top: auto;
    bottom: 0;
  }
}

.o-section--streamers::before {
  left: auto;
  right: 100%;
  width: 574px;
  height: 600px;
  background-image: url('../assets/images/section-streamers-a.png');
  background-position: left top;
  z-index: 100;
  transform: translateX(104px) translateY(-24px);
}

.o-section--streamers::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 100%;
  width: 595px;
  height: 564px;
  background-image: url('../assets/images/section-streamers-b.png');
  background-size: contain;
  background-position: right bottom;
  background-repeat: no-repeat;
  pointer-events: none;
  z-index: 100;
  transform: translateX(-143px) translateY(-350px);
}

.o-section--dense-strokes::before {
  max-width: 5690px;
  width: (5690px/$content-width)*100%;
  padding-top: (2878px/$content-width)*100%;
  background-image: url('../assets/images/section-dense-strokes.png');
  transform: translateX(-50%) translateY(-32.5%);
}

.o-section--light-strokes::before {
  max-width: 4645px;
  width: (4645px/$content-width)*100%;
  padding-top: (2564px/$content-width)*100%;
  background-image: url('../assets/images/section-light-strokes.png');
  transform: translateX(-50%) translateY(-32%);
}

.o-section--bye::before {
  left: auto;
  right: 50%;
  max-width: 2458px;
  width: (2458px/$content-width)*100%;
  padding-top: (2358px/$content-width)*100%;
  background-image: url('../assets/images/section-bye.png');
  background-position: right top;
  transform: translateX(18.5%) translateY(-13.5%);
}

