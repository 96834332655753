@charset "UTF-8";
/*
  Project: Get Fives Landing
  Author: Urszula Wielgosz
 */
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* Content
   ========================================================================== */
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  /* Change the default font family in all browsers (opinionated). */
  font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif;
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.3rem;
}

/**
 * Consistent indentation for lists.
 */
dd, ol, ul {
  margin-left: 1rem;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: '“' '”' '‘' '’';
  font-style: italic;
}

blockquote p:first-child:before {
  content: open-quote;
}

blockquote p:last-child:after {
  content: close-quote;
}

body {
  overflow: hidden;
  /* Crop sections backgrounds */
  padding: 0 1rem;
}

button,
input,
optgroup,
select,
textarea {
  line-height: 1.67;
}

input,
optgroup,
select,
textarea {
  display: block;
  width: 100%;
  border-width: 0 0 2px 0;
  border-style: solid;
  padding: 0.15em 0;
  color: #273360;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1 {
  font-family: "Merriweather", serif;
  font-size: 2.25rem;
  line-height: 1.5;
}

h2 {
  font-size: 1.25rem;
}

h3 {
  font-size: 0.83rem;
  font-weight: 500;
  text-transform: uppercase;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  background: #fff;
  color: #273360;
  font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif;
  font-size: 0.8125rem;
  /* [1] */
  /* 13px */
  font-weight: 500;
  line-height: 1.67;
  /* [1] */
  min-height: 100%;
  /* [3] */
  overflow-y: scroll;
  /* [2] */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-shadow: 0 0 1px #fff;
}

@media (min-width: 768px) {
  html {
    font-size: 0.875em;
    /* 14px */
    font-weight: 300;
    text-shadow: none;
  }
}

@media (min-width: 1024px) {
  html {
    font-size: 0.9375em;
    /* 15px */
  }
}

@media (min-width: 1280px) {
  html {
    font-size: 1.125em;
    /* 18px */
  }
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  vertical-align: middle;
  /* [3] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  max-width: none;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #ff2329;
  text-decoration: underline;
}

a:hover {
  color: #273360;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

.o-form {
  display: block;
  font-size: 1.12rem;
  font-weight: normal;
}

.o-form__item {
  display: block;
  position: relative;
  margin: 0 0 30px;
}

.o-form__label {
  position: absolute;
  top: 0.15em;
  pointer-events: none;
  transition: all 300ms ease-in-out;
}

.is-touched .o-form__label,
.no-js .o-form__label {
  font-size: 0.6em;
  top: -0.83em;
}

.o-form__action {
  margin-top: 65px;
  text-align: right;
}

.o-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1em;
}

.o-icon__symbol {
  display: block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}

@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1 1 auto;
}

.o-layout__item--pull {
  order: -1;
}

.o-layout--guttered {
  justify-content: space-between;
}

.o-layout--guttered > .o-layout__item {
  flex: none;
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: 40%;
  }
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: 29.5%;
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 18.2%;
  }
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}

.o-media:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}

.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}

.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

.o-section {
  position: relative;
  margin: 5.55em 0 0;
  /* approx. 150px on xlarge */
}

@media (min-width: 768px) {
  .o-section {
    margin-top: 8.33em;
    /* approx. 150px on xlarge */
  }
}

.o-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  z-index: -1;
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  pointer-events: none;
}

@media (min-width: 768px) {
  .o-section--large-spacing {
    margin-top: 16.65em;
    /* aprox. 300px on xlarge */
  }
}

@media (min-width: 768px) {
  .o-section--xlarge-spacing {
    margin-top: 22.2em;
    /* aprox. 400px on xlarge */
  }
}

.o-section--mobile-translucent::before {
  opacity: 0.5;
}

@media (min-width: 768px) {
  .o-section--mobile-translucent::before {
    opacity: 1;
  }
}

.o-section--five::before {
  width: 153.10219%;
  max-width: 1678px;
  padding-top: 146.62409%;
  background-image: url("../assets/images/section-five.png");
  background-position: left top;
  transform: translateX(-15.5%) translateY(-58%);
}

@media (min-width: 768px) {
  .o-section--five::before {
    transform: translateX(-15.5%) translateY(24%);
    top: auto;
    bottom: 0;
  }
}

.o-section--streamers::before {
  left: auto;
  right: 100%;
  width: 574px;
  height: 600px;
  background-image: url("../assets/images/section-streamers-a.png");
  background-position: left top;
  z-index: 100;
  transform: translateX(104px) translateY(-24px);
}

.o-section--streamers::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 100%;
  width: 595px;
  height: 564px;
  background-image: url("../assets/images/section-streamers-b.png");
  background-size: contain;
  background-position: right bottom;
  background-repeat: no-repeat;
  pointer-events: none;
  z-index: 100;
  transform: translateX(-143px) translateY(-350px);
}

.o-section--dense-strokes::before {
  max-width: 5690px;
  width: 519.16058%;
  padding-top: 262.59124%;
  background-image: url("../assets/images/section-dense-strokes.png");
  transform: translateX(-50%) translateY(-32.5%);
}

.o-section--light-strokes::before {
  max-width: 4645px;
  width: 423.81387%;
  padding-top: 233.94161%;
  background-image: url("../assets/images/section-light-strokes.png");
  transform: translateX(-50%) translateY(-32%);
}

.o-section--bye::before {
  left: auto;
  right: 50%;
  max-width: 2458px;
  width: 224.27007%;
  padding-top: 215.14599%;
  background-image: url("../assets/images/section-bye.png");
  background-position: right top;
  transform: translateX(18.5%) translateY(-13.5%);
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1096px;
}

.o-wrapper:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */
/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */
.c-btn {
  display: inline-block;
  /* [1] */
  width: auto;
  vertical-align: middle;
  /* [2] */
  font: inherit;
  /* [3] */
  font-size: 0.88rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  text-shadow: none;
  text-transform: uppercase;
  text-align: center;
  /* [4] */
  margin: 0;
  /* [4] */
  cursor: pointer;
  /* [5] */
  padding: 0.6rem 2.5rem;
  border: 0;
  transition: all 300ms ease-in-out;
  border-radius: 1.33rem;
}

/* Style variants
   ========================================================================== */
.c-btn--primary {
  background-color: #ff2329;
}

.c-btn--primary, .c-btn--primary:hover, .c-btn--primary:active, .c-btn--primary:focus {
  text-decoration: none;
  /* [4] */
  color: #fff;
}

.c-btn--primary:hover, .c-btn--primary:focus {
  background-color: #273360;
}

/* Size variants
   ========================================================================== */
.c-btn--small {
  padding: 0.3rem 0.5rem;
}

.c-btn--large {
  padding: 0.9rem 1.5rem;
}

.c-case-study {
  list-style: none;
  padding: 0;
  margin: 0;
  background: #fff;
  box-shadow: 40px 38px 60px rgba(39, 51, 96, 0.15);
  border-radius: 0 0 1.5rem 1.5rem;
}

.c-case-study__item {
  margin: 0;
}

.c-case-study__description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
}

@media (min-width: 768px) {
  .c-case-study__description {
    padding: 50px 120px;
  }
}

.c-case-study__hero {
  position: relative;
}

.c-case-study__item:nth-child(even) .c-case-study__hero {
  order: -1;
}

.c-case-study__image {
  display: block;
  width: 100%;
}

.c-case-study__item:last-child .c-case-study__image {
  border-radius: 0 0 1.5rem 1.5rem;
}

@media (min-width: 768px) {
  .c-case-study__item:last-child:nth-child(odd) .c-case-study__image {
    border-radius: 0 0 1.5rem 0;
  }
}

@media (min-width: 768px) {
  .c-case-study__item:last-child:nth-child(even) .c-case-study__image {
    border-radius: 0 0 0 1.5rem;
  }
}

.c-case-study__more {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 768px) {
  .c-contact-form {
    margin: 0 0 0 auto;
    max-width: 370px;
    color: #fff;
  }
}

.c-contact-form__input {
  background: transparent;
  color: #273360;
  border-bottom-color: #273360;
}

@media (min-width: 768px) {
  .c-contact-form__input {
    color: #fff;
    border-bottom-color: #fff;
  }
}

.c-feature {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding-left: 24px;
  padding-right: 24px;
  text-align: center;
  transition: all 300ms ease-in-out;
}

@media (min-width: 1024px) {
  .c-feature {
    padding-left: 48px;
    padding-right: 48px;
  }
}

.c-feature--card {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: left;
  background: #fff;
  border-radius: 0 0 1.5rem 1.5rem;
  box-shadow: 40px 38px 60px rgba(39, 51, 96, 0.15);
}

.c-feature__description {
  height: 100%;
}

.c-feature__image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 168px;
  max-height: 9.33em;
  width: 100%;
  margin-bottom: 40px;
}

.c-feature__image img {
  max-width: 100%;
  max-height: 100%;
}

.c-feature__image img[src$='@2x.png'] {
  transform: scale(0.5);
  max-width: 200%;
  max-height: 200%;
}

.c-feature-list {
  list-style: none;
}

.c-feature-list__item {
  text-align: center;
}

.c-feature-list__icon {
  margin-right: 15px;
  font-size: 0.8rem;
  color: #4e72fc;
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  margin: 5.55em auto -38px;
  padding: 5.55em 2.83em;
  border-bottom: 38px solid #fff;
  background: #fff;
  border-radius: 1.5rem 1.5rem 0 0;
  box-shadow: 40px -38px 60px rgba(39, 51, 96, 0.15);
}

@media (min-width: 768px) {
  .c-footer {
    padding: 110px 130px 90px;
  }
}

.c-footer p {
  margin: 0;
  text-align: center;
}

@media (min-width: 768px) {
  .c-footer p {
    text-align: right;
  }
}

.c-footer__by {
  display: block;
  margin: 0 0 30px 0;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.1em;
}

@media (min-width: 768px) {
  .c-footer__by {
    display: inline;
    margin: 0 60px 0 0;
  }
}

.c-footer__by a {
  color: #273360;
  text-decoration: none;
}

.c-footer__by a:hover {
  color: #ff2329;
}

.c-getfives {
  display: inline-block;
  width: 170px;
  height: 38px;
  overflow: hidden;
  vertical-align: baseline;
  margin-bottom: -12px;
}

.c-getfives__logo {
  float: left;
  height: 38px;
  width: 37px;
  margin-right: 23px;
  fill: #4e72fc;
}

.c-getfives__text {
  float: left;
  height: 15px;
  width: 110px;
  margin-top: 12px;
  fill: #273360;
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  margin: 4.45em auto;
}

.c-hero {
  max-width: 294px;
}

@media (min-width: 768px) {
  .c-hero {
    max-width: none;
  }
}

.c-hero__tags {
  font-weight: 700;
}

.c-layout-full {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.c-layout-full__main {
  top: 12%;
}

.c-module--half {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.c-module__title {
  margin-bottom: 0;
}

.c-more {
  margin: 2em 0;
  text-align: center;
  font-size: 1.67rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
}

.c-slider {
  margin: 5.55em 0;
  width: 100%;
}

@media (min-width: 768px) {
  .c-slider {
    margin: 11.1em 0 2.78em;
  }
}

.c-slider__frame {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.c-slider__list {
  position: relative;
  width: 100%;
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  transition: transform 300ms ease-in-out;
  flex-wrap: wrap;
  justify-content: center;
}

.is-transition-disabled .c-slider__list {
  transition-duration: 0ms;
}

.is-enabled .c-slider__list {
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.c-slider__item {
  position: relative;
  flex: 0 0 auto;
  margin: 0;
  padding: 10px;
  width: 100%;
  pointer-events: none;
}

.c-slider__item.is-active {
  pointer-events: auto;
}

@media (min-width: 480px) {
  .c-slider__item {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .c-slider__item {
    width: 33.33%;
  }
}

@media (min-width: 1024px) {
  .c-slider__item {
    padding: 20px;
  }
}

.c-slider__item.js-clone-prev {
  order: -1;
}

.c-slider__item.js-clone-next {
  order: 1;
}

.c-slider__content {
  width: 100%;
  pointer-events: none;
}

.is-past .c-slider__content,
.is-future .c-slider__content {
  background: rgba(255, 255, 255, 0.5);
}

.is-transition-disabled .c-slider__content {
  transition-duration: 0ms;
}

@media (min-width: 1600px) {
  .is-enabled .c-slider__content {
    backface-visibility: hidden;
    margin-left: -100vw;
    opacity: 0;
  }
  .is-past .c-slider__content {
    margin-left: -50vw;
    transform: translateX(731px);
    opacity: 1;
  }
  .is-active .c-slider__content {
    margin-left: 0vw;
    /* stylelint-disable-line length-zero-no-unit */
    /* Fix IE11 flickering bug */
    transform: translateX(0);
    opacity: 1;
  }
  .is-future .c-slider__content {
    margin-left: 50vw;
    transform: translateX(-731px);
    opacity: 1;
  }
  .is-future + .c-slider__item .c-slider__content {
    margin-left: 100vw;
  }
}

.c-slider__frame--uncropped {
  overflow: visible;
}

.c-testimonial {
  padding: 15px;
  text-align: center;
}

.c-testimonial__avatar {
  display: block;
  width: 281px;
  max-width: 100%;
  margin: 0 auto 3em;
  border-radius: 50%;
}

.c-testimonial__name {
  margin-bottom: 0;
}

.c-testimonial__list > li {
  margin-bottom: 0;
}

.c-xfive {
  display: inline-block;
  height: 30px;
  width: 83px;
  overflow: hidden;
  vertical-align: baseline;
  margin-bottom: -0.14em;
}

.c-xfive__x {
  display: block;
  float: left;
  fill: #273360;
  margin: 9px 1px 0 0;
  height: 20px;
  width: 20px;
  transition: fill 300ms ease-in-out;
}

.c-xfive:hover .c-xfive__x {
  fill: #ef4c23;
}

.c-xfive__five {
  display: block;
  float: left;
  fill: #273360;
  height: 30px;
  width: 62px;
  transition: fill 300ms ease-in-out;
}

.c-xfive:hover .c-xfive__five {
  fill: #231f20;
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.u-destressed {
  display: block;
  font-weight: 300;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}

@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}

@media (min-width: 768px) {
  .u-unbreakable {
    white-space: nowrap;
  }
}
