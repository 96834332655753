button,
input,
optgroup,
select,
textarea {
  line-height: 1.67;
}

input,
optgroup,
select,
textarea {
  display: block;
  width: 100%;
  border-width: 0 0 2px 0;
  border-style: solid;
  padding: 0.15em 0;
  color: $color-text;
}
