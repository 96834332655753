.o-form {
  display: block;
  font-size: 1.12rem;
  font-weight: normal;
}

.o-form__item {
  display: block;
  position: relative;
  margin: 0 0 30px;
}

.o-form__label {
  position: absolute;
  top: 0.15em;
  pointer-events: none;
  transition: $global-transition;

  .is-touched &,
  .no-js & {
    font-size: 0.6em;
    top: -0.83em;
  }
}

.o-form__action {
  margin-top: 65px;
  text-align: right;
}
