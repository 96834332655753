.c-getfives {
  display: inline-block;
  width: 170px;
  height: 38px;
  overflow: hidden;
  vertical-align: baseline;
  margin-bottom: -12px;
}

.c-getfives__logo {
  float: left;
  height: 38px;
  width: 37px;
  margin-right: 23px;
  fill: $color-blue;
}

.c-getfives__text {
  float: left;
  height: 15px;
  width: 110px;
  margin-top: 12px;
  fill: $color-text;
}
