.c-layout-full {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  &__main {
    top: 12%;
  }
}
