.c-testimonial {
  padding: 15px;
  text-align: center;
}

.c-testimonial__avatar {
  display: block;
  width: 281px;
  max-width: 100%;
  margin: 0 auto 3em;
  border-radius: 50%;
}

.c-testimonial__name {
  margin-bottom: 0;
}

.c-testimonial__list {
  > li {
    margin-bottom: 0;
  }
}
