.c-hero {
  max-width: 294px;

  @include bp-medium {
    max-width: none;
  }
}

.c-hero__tags {
  font-weight: 700;
}
