/* ==========================================================================
   #FOOTER
   ========================================================================== */

$footer-shadow-top-offset: -38px;

.c-footer {
  margin: 5.55em auto $footer-shadow-top-offset;
  padding: 5.55em 2.83em;
  border-bottom: -$footer-shadow-top-offset solid $color-bg;
  background: $color-bg;
  border-radius: $content-radius $content-radius 0 0;
  box-shadow: 40px $footer-shadow-top-offset $content-shadow-blur $content-shadow-color;

  @include bp-medium {
    padding: 110px 130px 90px;
  }

  p {
    margin: 0;
    text-align: center;

    @include bp-medium {
      text-align: right;
    }
  }
}

.c-footer__by {
  display: block;
  margin: 0 0 30px 0;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.1em;

  @include bp-medium {
    display: inline;
    margin: 0 60px 0 0;
  }

  a {
    color: $color-text;
    text-decoration: none;

    &:hover {
      color: $color-link;
    }
  }
}
