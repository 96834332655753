.c-xfive {
  display: inline-block;
  height: 30px;
  width: 83px;
  overflow: hidden;
  vertical-align: baseline;
  margin-bottom: -0.14em;
}

.c-xfive__x {
  display: block;
  float: left;
  fill: $color-text;
  margin: 9px 1px 0 0;
  height: 20px;
  width: 20px;
  transition: fill $global-partial-transition;

  .c-xfive:hover & {
    fill: $color-xfive-red;
  }
}

.c-xfive__five {
  display: block;
  float: left;
  fill: $color-text;
  height: 30px;
  width: 62px;
  transition: fill $global-partial-transition;

  .c-xfive:hover & {
    fill: $color-xfive-black;
  }
}
