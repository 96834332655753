.c-case-study {
  list-style: none;
  padding: 0;
  margin: 0;
  background: $color-bg;
  box-shadow: $content-shadow;
  border-radius: 0 0 $content-radius $content-radius;
}

.c-case-study__item {
  margin: 0;
}

.c-case-study__description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;

  @include bp-medium {
    padding: 50px 120px;
  }
}

.c-case-study__hero {
  position: relative;

  .c-case-study__item:nth-child(even) & {
    order: -1;
  }
}

.c-case-study__image {
  display: block;
  width: 100%;

  .c-case-study__item:last-child & {
    border-radius: 0 0 $content-radius $content-radius;
  }

  .c-case-study__item:last-child:nth-child(odd) & {
    @include bp-medium {
      border-radius: 0 0 $content-radius 0;
    }
  }

  .c-case-study__item:last-child:nth-child(even) & {
    @include bp-medium {
      border-radius: 0 0 0 $content-radius;
    }
  }
}

.c-case-study__more {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
